<template>
  <div class="page container">
    <div class="title-2">Документы</div>

    <div class="list">
      <a
        download
        v-for="doc in $store.state.strapiData.documents"
        :key="doc.title"
        class="doc"
        :href="doc.file"
        target="_blank"
      >
        <div class="title-4">{{doc.title}}</div>
        <img src="@/assets/images/round-arrow-black.svg" />
      </a>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.title-2{
  margin-bottom: 60px;
  margin-top: 60px;
  @media (max-width: 700px){
    margin-bottom: 30px;
    margin-top: 30px;
  }
}
.list{
  gap: 10px 0;
  display: flex;
  flex-direction: column;
}
.doc{
  background: #F7F7F7;
  padding: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  .title-4{
    color: rgba(#161616, .5);
    transition: ease .5s;
  }
  img{
    opacity: .4;
    transform: rotate(-45deg);
    transition: ease .5s;
    margin-left: 30px;
  }
  &:hover{
    .title-4{
      color: rgba(#161616, 1);
    }
    img{
      opacity: 1;
      transform: rotate(0deg);
    }
  }
}
</style>